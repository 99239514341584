import React from 'react';

const Footer = () => (
  <div className="footer">
    <div className="inner">
      <div className="footer-text">
        <span className="heavy">Klubstrøm A/S</span>
        <br />
        <br />
        Telefon: <a href="tel:+4589880959">89 88 09 59</a>&nbsp;
        <br />
        Mail:&nbsp;
        <a href="mailto:kundeservice@klubstrom.dk">kundeservice@klubstrom.dk</a>
        <br />
        Klubstrøm A/S CVR: 34608784
        <br />
        Købmagergade 22, 1. sal
        <br />
        1150 København K
      </div>
      <div className="footer-text-small">
        Klubstrøm er et 100% danskejet elselskab som leverer klimavenlig strøm
        fra nye danske vindmøller og solcelleanlæg. Med Klubstrøm er du med til
        at støtte en grøn omstilling. Vi vil SPARE på og PRODUCERE klimavenlig
        strøm SAMMEN med vores kunder, med en økonomisk gevinst for alle ombord.
      </div>
    </div>
  </div>
);

export default Footer