import React from 'react';
import logo from '../media/klubstrom_logo.png'

const Header = () => (
    <div className="header">
    <div className="header-container">
        <div className="logo"><a href="http://klubstrom.dk"><img src={logo} alt='Blueenergy Logo' width="80%" /></a></div>
    </div>
</div>

)

export default Header;