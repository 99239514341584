import React from "react";
import logo from "../../media/emark-logo-new.png";
import Lottie from "react-lottie";
import * as animationData from "../../loading.json";

const Box = (props) => {
  var { value, label } = props;

  value = value + "";

  let includes_link = String(value).toLowerCase().includes("https://");

  if (!includes_link) {
    return (
      <div className="form-group">
        <label className="col-sm-4 control-label">{label}</label>
        <div className="col-sm-7">
          <label className="control-label disabled">
            <div style={{ textAlign: "left" }}>
              {value.split("\n").map((i, key) => {
                return (
                  <p style={{ wordWrap: "break-word" }} key={key}>
                    {i}
                  </p>
                );
              })}
            </div>
          </label>
        </div>
      </div>
    );
  } else {
    return (
      <div className="form-group">
        <label className="col-sm-4 control-label">{label}</label>
        <div className="col-sm-7">
          <label className="control-label disabled">
            <a href={value} target="_blank">
              <div style={{ textAlign: "left" }}>
                <p style={{ wordWrap: "break-word" }}>Læs mere her</p>
              </div>
            </a>
          </label>
        </div>
      </div>
    );
  }
};

const Footer = (props) => (
  <div className="col-lg-3 col-sm-6 col-lg-pull-9">
    <h3>Brug for hjælp</h3>
    <p>
      Kontakt os endelig. Vores kundeservice er åben mandag - torsdag mellem kl.
      9.00 - 16.30 og fredag mellem 9.00 - 15.00. Ring på tlf.{" "}
      <a href="tel:+4589880959">89 88 09 59</a>&nbsp;eller skriv på{" "}
      <a href="mailto:kundeservice@klubstrom.dk">kundeservice@klubstrom.dk</a>
    </p>
    <a
      href="https://certifikat.emaerket.dk/eshop/blueenergy.dk"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={logo}
        width="35%"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
    </a>
    <h3>Sikker handel</h3>
    <p>
      E-mærket er din garanti for, at vi lever op til gældende dansk lov samt en
      række særlige forbrugerbeskyttende krav.{" "}
      <a
        href="https://www.emaerket.dk/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Læs mere her.
      </a>
    </p>
  </div>
);

const PrivateHeader = (props) => (
  <>
    <p>Kære {props.name}</p>
    <p>
      Tak for din interesse! Her finder du det aftalte tilbud. Du kan acceptere
      tilbuddet, ved at klikke på knappen "Accepter tilbud" nedenfor. Ved at
      klikke på knappen accepterer du samtidigt salgs- og
      leveringsbetingelserne.{" "}
    </p>
    <p>
      {" "}
      Link til vores salgs- og leveringsbetingelser finder du{" "}
      <a href={props.terms} target="_blank" rel="noopener noreferrer">
        her
      </a>
      .
    </p>
    <p></p>
  </>
);

const ContainerTop = (props) => {
  if (!props.loaded) {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
    };

    return (
      <div className="container top">
        <div className="col-lg-12 col-sm-12">
          <Lottie options={defaultOptions} height={200} width={200} />
        </div>
      </div>
    );
  }

  var data = props.data;
  var city = data.zip + " " + data.city;
  var terms = "https://www.klubstrom.dk/privatbetingelser/";
  if (data.customer_type === "private") {
    var cpr = data.birthdate + "-****";
    return (
      <div className="container top">
        <div className="col-lg-9 col-lg-push-3 col-sm-12">
          <PrivateHeader name={data.name} terms={terms} />
          {data.confirmed == 1 ? (
            <button
              className="btn btn-primary btn-block"
              style={{ fontSize: 20 }}
              disabled="true"
            >
              Du har allerede accepteret tilbuddet. Se oplysninger nedenfor:
            </button>
          ) : (
            ""
          )}
          <form className="form-horizontal">
            <div className="well">
              <Box label="Navn:" value={data.name} />
              <Box label="Adresse:" value={data.address} />
              <Box label="By:" value={city} />
              <Box label="CPR-nummer:" value={cpr} />
              <Box label="Telefon:" value={data.phone} />

              {data.orders.map((order) => (
                <div key={order.id}>
                  <div className="form-group">
                    <div className="col-sm-12">
                      <h5 className="text-center">Produktoplysning</h5>
                    </div>
                  </div>
                  <Box label="Produkt:" value={order.name} />
                  {order.binding_period != null ? (
                    <Box label="Binding:" value={order.binding_period} />
                  ) : null}

                  {order?.fields?.map((field) => (
                    <Box label={`${field.key}:`} value={field.value} />
                  ))}
                </div>
              ))}
            </div>
            <div className="form-group">
              <div className="col-sm-12 col-md-12">
                <button
                  onClick={props.onSubmit}
                  className="btn btn-primary btn-block"
                  style={{ fontSize: 30 }}
                  disabled={data.confirmed == 1 ? true : false}
                >
                  {data.confirmed == 1
                    ? "Tilbud er blevet accepteret"
                    : "Accepter tilbud"}
                </button>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }

  if (data.customer_type === "business") {
    terms = "https://www.klubstrom.dk/erhvervsbetingelser/";
    return (
      <div className="container top">
        <div className="col-lg-9 col-lg-push-3 col-sm-12">
          <PrivateHeader name={data.name} terms={terms} />
          <form className="form-horizontal">
            <div className="well">
              <Box label="Navn:" value={data.name} />
              <Box label="Adresse:" value={data.address} />
              <Box label="By:" value={city} />
              <Box label="CVR-nummer:" value={data.cvr} />
              <Box label="Telefon:" value={data.phone} />

              {data.orders.map((order) => (
                <div key={order.id}>
                  <div className="form-group">
                    <div className="col-sm-12">
                      <h5 className="text-center">Produktoplysning</h5>
                    </div>
                  </div>
                  <Box label="Produkt:" value={order.name} />
                  {order.binding_period != null ? (
                    <Box label="Binding:" value={order.binding_period} />
                  ) : null}

                  {order.fields.map((field) => (
                    <Box label={`${field.key}:`} value={field.value} />
                  ))}
                  <Box
                    label="Information:"
                    value="Ved at acceptere dit tilbud, bekræfter du at der er erhvervsmæssigt forbrug på adressen."
                  />
                </div>
              ))}
            </div>
            <div className="form-group">
              <div className="col-sm-12 col-md-12">
                <button
                  onClick={props.onSubmit}
                  className="btn btn-primary btn-block"
                  style={{ fontSize: 30 }}
                >
                  Accepter tilbud
                </button>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
};

export default ContainerTop;
